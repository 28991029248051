import { createSession } from '@fh-components/fh-js-api-core/store/slices/sessionSlice';

export default function withSession(f) {
	return async args => {
		const { reduxStore } = args;
		await reduxStore.dispatch(createSession());

		return f(args);
	};
}

export function withSessionRefreshed(f) {
	return async args => {
		const { reduxStore } = args;
		await reduxStore.dispatch(createSession(true));

		return f(args);
	};
}
