import React from 'react';
import { changeRedirectUrl } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import Router from 'next/router';
import Head from 'next/head';
import parseCookies from '@/lib/parseCookies';
import withSession from '@/lib/withSession';
import { isLoggedInSelector } from '@/store';
import { LoginPage } from '@/layouts';

const NextLoginPage = () => {
	return (
		<>
			<Head>
				<title>Авторизация</title>
			</Head>

			<LoginPage />
		</>
	);
}

NextLoginPage.getInitialProps = withSession(async ({ res, req, reduxStore }) => {
	// If user is already logged in
	const loggedIn = isLoggedInSelector(reduxStore.getState());

	if (loggedIn) {
		if (res) {
			res.writeHead(302, {
				Location: '/',
			});
			res.end();
		} else {
			Router.push('/');
		}
	} else if (res) {
		const cookies = parseCookies(req);
		const redirectUrl = cookies.fh_redirect_url;

		if (redirectUrl) {
			await reduxStore.dispatch(
				changeRedirectUrl({
					href: redirectUrl,
					asPath: redirectUrl,
				}),
			);
		}
		
	}
	return {};
});

export default NextLoginPage;